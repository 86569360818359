
<template>
  <div
    :class="isModal ? 'datePickerFilterModal' : 'datePickerFilter'"
  >
    <!-- <div class="fieldWrapper"> -->
    <span v-if="showLabel">Filter Start Date:</span>
    <datepicker
      title="Start Date"
      field="start_date"
      :value="start_date"
      @dateChaged="dateHandler"
    />
    <!-- </div> -->
    <!-- <div class="fieldWrapper"> -->
    <span v-if="showLabel">Filter End Date:</span>
    <datepicker
      size="lg"
      title="End Date"
      field="end_date"
      :value="end_date"
      @dateChaged="dateHandler"
    />
    <!-- </div> -->
    <!-- <div class="fieldWrapper"> -->

    <span v-if="showLabel">Select Date to Filter By</span>
    <b-form-select
      :value="filterDateField"
      @change="handleFilterDateField"
      style="max-width: 200px"
    >
      <b-form-select-option disabled value="">Date Type</b-form-select-option>
      <b-form-select-option 
        v-if="canFilter('order_date')" 
        value="order_date"
      >
        Order Date
      </b-form-select-option>
      <b-form-select-option 
        v-if="canFilter('uop_processed_date')" 
        value="uop_processed_date"
      >
        UOP Processed Date
      </b-form-select-option>
      <b-form-select-option 
        v-if="canFilter('uop_banner_registration_date')" 
        value="uop_banner_registration_date"
      >
        UOP Banner Registration Date
      </b-form-select-option>
      <b-form-select-option 
        v-if="canFilter('uop_banner_grade_validation_date')" 
        value="uop_banner_grade_validation_date"
      >
        UOP Banner Grade Date
      </b-form-select-option>
      <b-form-select-option 
        v-if="canFilter('grade_submission_date')" 
        value="grade_submission_date"
      >
        Grade Submission Date
      </b-form-select-option>
      <b-form-select-option 
        v-if="canFilter('ld_coursework_submitted_date')" 
        value="ld_coursework_submitted_date"
      >
        Coursework Submitted Date
      </b-form-select-option>
      <b-form-select-option 
        v-if="canFilter('refund_date')" 
        value="refund_date"
      >
        UOP Refund Date
      </b-form-select-option>
      <b-form-select-option
        v-if="canFilter('processed_and_refunded_date')"
        value="processed_and_refunded_date"
      >
        UOP Invoice Filter
      </b-form-select-option>
      <b-form-select-option
        v-if="canFilter('uc_invoice_date')"
        value="uc_invoice_date"
      >
        University Credit Invoice
      </b-form-select-option>
    </b-form-select>
    <!-- </div> -->
    <!-- :disabled="!start_date || !end_date || !filterDateField" -->
    <b-button
      v-if="!isModal"
      variant="primary"
      :disabled="!(start_date && end_date && filterDateField)"
      @click="applyFilter"
      >Apply</b-button
    >
    <b-button
      variant="warning"
      style="color:white;"
      v-if="(start_date && end_date && filterDateField) && !isModal"
      @click="resetFilters({type: 'date'})"
    >
      Clear</b-button
    >
  </div>
</template>

<script>
import Datepicker from "../Atoms/Datepicker.vue";
export default {
  name: "DatepickerFilter",
  props: {
    start_date: {},
    end_date: {},
    filterDateField: {},
    dateHandler: {},
    applyFilter: {},
    resetFilters: {},
    showLabel: {
      default: false,
    },
    isModal: {
      default: false,
    },
  },
  components: { Datepicker },
  methods: {
    handleFilterDateField(newValue) {
      this.$emit("handleFilterDateField", newValue);
    },
    canFilter(date_key) {
      if(
          !this.$store.state.user || 
          !this.$store.state.user.can_use_date_filters ||
          this.$store.state.user.can_use_date_filters.length < 1
        ) {
        return false
      }
      return this.$store.state.user.can_use_date_filters.includes(date_key)
    },
  }
};
</script>
<style lang="scss" scoped>
.datePickerFilter {
  display: flex;
  justify-content: center;
  // flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 0.5rem;
  max-width: 100%;
  width: 720px;
}
.datePickerFilterModal {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  max-width: 100%;
  width: 720px;
}
</style>