const ENUMS = {
    VIEWS: {
        GRADES_NEEDED_TO_BE_ENTERED_IN_BANNER: 'View Grades Needed To be Entered in Banner',
        REGISTRATIONS_NEEDED_TO_BE_ENTERED_IN_BANNER: 'View Registrations Needed To Be Entered in Banner',
        PROCESSED_REGISTRATIONS: 'View Processed Registrations',
        UNVALIDATED_REGISTRATIONS: 'View Unvalidated Registrations',
        IN_PROGRESS_LD: 'View In-Progress TF Assignments',
        DELETED: 'View Deleted Registrations',
        FAILED: "View Failed Payment Registrations",
        REFUNDED: "View Refunded Registrations"
    },
    MT_CARD_TYPE: "VISA"
}

export default ENUMS