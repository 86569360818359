<template>
    <!-- <label>{{title}}</label> -->
    <b-datepicker
      v-b-tooltip.hover
      :title="title"
      :placeholder="title"
      :value="value"
      @context="contextHandler"
      :date-format-options="{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }"
    />
</template>

<script>
export default {
  name: "DatePicker",
  props: ["title", "field", "value"],
  methods: {
    contextHandler(data) {
      this.$emit("dateChaged", { field: this.field, data });
    },
  },
};
</script>