<template>
  <b-modal
    id="filter-modal"
    centered
    title="Set Date Filter"
        cancel-disabled
    ok-disabled
  >
  <template #default="{cancel}">
    <slot name="dateFilter" :cancel="cancel"></slot>
  </template>
    <template
      #modal-footer="{
        cancel /* hide, ok */
      }"
    >
    <b-button @click="() => resetFilters({cancel})">
      Clear
    </b-button>
    
    <b-button variant="primary" @click="() => applyFilter(cancel)">
      Apply
    </b-button>
    </template>
    
  </b-modal>
</template>

<script>
export default {
  props: {
    stage: {
      type: String,
      default: "",
    },
    order: {
      type: Object,
      default: () => ({}),
    },
    resetFilters: {},
    applyFilter: {},
  },
  watch: {
    stage(newVal){
      if(newVal === 'success' || newVal === 'error') {
        this.$emit('closeDialog')
        this.$root.$emit('bv::hide::modal', 'notes-modal')
      }
    }
  }
};
</script>


<style scoped>
.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
</style>