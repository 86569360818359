var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-section"},[_vm._m(0),_c('div',{staticClass:"head-buttons"},[_c('b-dropdown',{attrs:{"variant":"link","right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-avatar',{attrs:{"variant":"primary","size":"3rem"}},[_c('strong',[_vm._v(_vm._s(_vm.$store.getters.getAvatarInitial))])])]},proxy:true}])},[_c('b-dropdown-item',{staticClass:"text-right",attrs:{"variant":"warning","href":"mailto:admin@universitycredit.com","target":"_blank"}},[_vm._v("Report a Bug")]),( 
                _vm.parent == 'dashboard' &&
                _vm.$store.state.user && 
                _vm.$store.state.user.adminPartners &&  
                _vm.$store.state.user.adminPartners.length > 0
              )?_c('b-dropdown-item',{staticClass:"text-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push('partner_profile')}}},[_vm._v(" Partner Profile"+_vm._s(_vm.$store.state.user.adminPartners.length > 1 ? 's' : '')+" ")]):_vm._e(),_c('b-dropdown-item',{staticClass:"text-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$store.dispatch('logout')}}},[_vm._v("Sign Out")])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-title"},[_c('h4',{staticClass:"m-0 font-weight-bold"},[_vm._v("University Credit")])])
}]

export { render, staticRenderFns }