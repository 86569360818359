<template>
  <div class="head-section">
      <div class="head-title">
        <h4 class=" m-0 font-weight-bold">University Credit</h4>
      </div>
      <div class="head-buttons">
         <b-dropdown variant="link" right no-caret>
          <template #button-content right>
            <b-avatar variant="primary" size="3rem"><strong>{{$store.getters.getAvatarInitial}}</strong></b-avatar>
          </template>
          <b-dropdown-item
          class="text-right"
          variant="warning"
          href="mailto:admin@universitycredit.com"
          target="_blank"
          >Report a Bug</b-dropdown-item
          >
          <b-dropdown-item
            v-if=" 
                  parent == 'dashboard' &&
                  $store.state.user && 
                  $store.state.user.adminPartners &&  
                  $store.state.user.adminPartners.length > 0
                "
            class="text-right"
            variant="primary"
            @click="$router.push('partner_profile')"
          >
            Partner Profile{{$store.state.user.adminPartners.length > 1 ? 's' : ''}}
          </b-dropdown-item>
          <b-dropdown-item
          class="text-right"
          variant="primary"
          @click="$store.dispatch('logout')"
          >Sign Out</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    parent: {
      type: String,
      default: "dashboard",
    },
  },
  data() {
    return {
    }
  }
}
</script>
      