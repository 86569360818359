<template>
  <b-modal
    id="assignments-modal"
    centered
    title="Learndash Course Progress"
    cancel-disabled
    ok-disabled
  >
    <template #default>
      Name: {{ order.first_name + " " + order.last_name }}<br>
      Course Number: {{ order.course ? order.course.course_number : "" }}<br>
      Course Title: {{ order.course ? order.course.course_title : "" }}<br>
      Credit(s): {{ order.course_credits }}<br>
      <p class="font-weight-bold mt-2">Course Section Progress:</p>
      <div class="progressBar">
        <div
          v-for="(lesson, key) in order.ld_course_status" 
          :key="key"
          :class="lesson.status"
          class="step"
        >
          <b-form-checkbox
            size="lg"
            :checked="lesson.status == 'completed' ? 'true' : 'false'"
            @change="markResource( lesson, 'lesson' )"
            v-b-tooltip.hover 
            :title="lesson.status == 'completed' ? 'Mark Incomplete' : 'Mark Complete'"
            :disabled="processingMark"
          ></b-form-checkbox>
          <p> {{lesson.title}}</p>
        </div>
        <div 
          class="step mt-3 pt-3"
          style="border-top: 1px solid gray; width: 100%;"
          :class="order.ld_coursework_submitted ? 'completed' : 'notcompleted'"
        >
          <b-form-checkbox
            size="lg"
            :checked="order.ld_coursework_submitted ? 'true' : 'false'"
            @change="markCoursework()"
            v-b-tooltip.hover 
            :title="order.ld_coursework_submitted ? 'Mark Submitted' : 'Mark Not-Submitted'"
            :disabled="processingMark"
          ></b-form-checkbox>
          <p>
            User Submitted Coursework 
            {{ 
              order.ld_coursework_submitted && order.ld_coursework_submitted_date ? 
              "("+formatDate(order.ld_coursework_submitted_date)+")" :
              ""
            }}
          </p>
        </div>
      </div>
      <p class="font-weight-bold">Assignments:</p>
      <div
        v-for="(assignment, key) in order.ld_assignments" 
        :key="key"
        :class="assignment.status"
        class="step"
      >
        <b-form-checkbox
        class="d-inline-block"
          size="lg"
          :checked="assignment.status == 'completed' ? 'true' : 'false'"
          @change="markResource( assignment, 'assignment' )"
          v-b-tooltip.hover 
          :title="assignment.status == 'completed' ? 'Mark Incomplete' : 'Mark Complete'"
          :disabled="processingMark"
        ></b-form-checkbox>
        <b-link
          class="d-inline-block"
          :href="`${assignment.link}`"
          target="_blank"
          >
            {{ assignment.title.replace(/assignment_[0-9]{1,6}_[0-9]{5,15}_/g, '\n').replace("Assignment ", "") }} 
            ({{new Date(assignment.date).toLocaleDateString() }})
        </b-link>
      </div>
      <p class="font-weight-bold mt-3">Course Objective:</p>
      <p v-if="order.course_objective">{{ order.course_objective }}</p>
      <a
        target="_blank"
        v-if="order.wc_user_id"
        :href="`https://teacherfriendly.com/wp-admin/edit.php?post_type=sfwd-essays&author=${order.wc_user_id}`"
      >
        Search for old course objective
        <br>
      </a>
    </template>

    <template #modal-footer="{ cancel /*hide, ok */ }">
      <b-button
        class="btn-red btn"
        variant="primary"
        @click="cancel"
      >
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => ({}),
    },
    processingMark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    totalCreditFee() {
      return (
        (this.order.course_credits ?? 0) * (this.order.course_credit_fee ?? 0) +
        (this.order.course_fixed_credit_fee ?? 0)
      );
    },
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        document.getElementById("modal").style.display = "block";
      } else {
        document.getElementById("modal").style.display = "none";
      }
    },
  },
  methods: {
    log(data) {
      console.log(data);
    },
    markResource(resource, type) {
      this.processingMark = true;
      const mark = resource.status == "completed" ? "notcompleted" : "completed";

      this.$store
        .dispatch("sendPutRequest", {
          endpointPath: "orders/"+this.order._id+"/markLdResource",
          body: {
            orderId: this.order._id, resourceId: resource.id, mark: mark, type: type
          }
        })
        .then((res) => {
          this.makeToast({ message: res.payload.message, variant: "success" });
          resource.status = mark
          this.processingMark = false;
        })
        .catch((err) => {
          this.processingMark = false;
          this.makeToast({ message: err.payload, variant: "danger" });
        });
    },
    markCoursework() {
      this.processingMark = true;

      this.$store
        .dispatch("updateOrder", {_id: this.order._id, ld_coursework_submitted: !this.order.ld_coursework_submitted})
        .then((res) => {
          this.makeToast({ message: res.payload.message, variant: "success" });
          this.order.ld_coursework_submitted = !this.order.ld_coursework_submitted
          this.processingMark = false;
        })
        .catch((err) => {
          this.makeToast({ message: err.payload, variant: "danger" });
          this.processingMark = false;
        });
    },
    formatDate(date) {
      const locale = 'en-US'
      const timeZone = 'America/Los_Angeles'
      return new Date(date).toLocaleDateString(locale, { timeZone: timeZone });
    },
  },
};
</script>


<style lang="scss" scoped>
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  .assignments a {
    overflow-wrap: break-word;
  }
}
.modal-dialog {
  footer {
    display: none;
  }
}
.progressBar {
    margin: 0 0 1rem 0;
    padding: 0;
    overflow: hidden;
    .step {
      list-style-type: none;
      float: left;
      position: relative;
      color: $gray-400;
      text-align: left;
      div {
        display: inline;
      }
    }
    p {
      display: inline;
    }
    .completed p {
      color: black
    }
}

</style>